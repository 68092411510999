<template>
  <div>
    <base-header :base-title="'Report Time Tracking Reservation'"></base-header>
    <!-- begin:: Content -->
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="'Report Time Tracking Reservation'"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row">
            <div class="col-md-8">
              <div class="form-inline">
                <div class="form-group mb-2 mr-4">
                  <div class="kt-radio-inline">
                    <label class="kt-radio">
                      <input
                        type="radio"
                        name="date_filter"
                        v-model.number="dateFilter"
                        value="1"
                        checked
                      /> Tanggal Dibuat
                      <span></span>
                    </label>
                    <label class="kt-radio">
                      <input
                        type="radio"
                        name="date_filter"
                        v-model.number="dateFilter"
                        value="2"
                      /> Tanggal Kunjungan
                      <span></span>
                    </label>
                  </div>
                </div>
                <div class="form-group mb-2">
                  <div class="input-group input-group-sm date">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="la la-calendar-o glyphicon-th"></i>
                      </span>
                    </div>
                    <date-range-picker
                      :changedValueStart.sync="dateStart"
                      :changedValueEnd.sync="dateEnd"
                      id-date-picker="filter_rangedatepicker"
                      picker-value
                      place-holder="DD/MM/YYYY - DD/MM/YYYY"
                      :periodeAwal="dateStart"
                      :periodeAkhir="dateEnd"
                    ></date-range-picker>
                  </div>
                </div>
                <button
                  type="button"
                  @click="submitSearch()"
                  class="btn btn-sm btn-default btn-bold btn-upper mb-2 ml-2"
                >
                  <i class="flaticon2-search"></i>
                  {{ $t("button.search") }}
                </button>
              </div>
            </div>
            <div class="col-md-4">
               <div class="dt-buttons btn-group pull-right" v-if="isLoaded">
                    <download-excel
                        class="btn btn-secondary buttons-excel buttons-html5"
                        :fetch="exportExcelData"
                        :export-fields="json_fields"
                        worksheet="My Worksheet"
                        name="laporan time tracking reservasi.xls"
                    >
                        <span>Excel</span>
                    </download-excel>
                    <button class="btn btn-secondary buttons-pdf buttons-html5" tabindex="0" aria-controls="table_reservation" type="button" @click="downloadPdf">
                    <span>PDF</span></button>
                </div>
            </div>
          </div>
           <div v-if="showLoading" class="m-auto p-5 d-flex flex-row h-100">
                <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
            <div v-if="isLoaded">
                <br/>
                <v-client-table ref="table" :data="tableData" :columns="columns" :options="options">
                </v-client-table>
            </div>
        </div>
      </div>
    </div>
    <!-- end:: Content -->
  </div>
</template>

<script>
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "./../../_general/AutoRefresh";
import BaseHeader from "./../../_base/BaseHeader";
import BaseTitle from "./../../_base/BaseTitle";
import BaseButtonModal from "./../../_base/BaseButtonModal";
import DateRangePicker from "./../../_general/CustomDateRangePicker";
import DataTables from "./../../_table/DataTables";
// import BtnAction from "./../_components/BtnAction";
// import LoadSpinner from "./../../_general/LoadSpinner";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("report");
import DateRange from './../../../constants/date-range-enum';
import SoundNotifiction from './../../_general/SoundNotification';
import StatusReservasi from './../../../constants/status-reservasi-enum';
import timestamp from './_components/timestamp.vue';

export default {
  components: {
    AutoRefresh,
    BaseHeader,
    DataTables,
    BaseTitle,
    BaseButtonModal,
    DateRangePicker,
    SoundNotifiction
  },
  data() {
    var vx = this;
    return {
      dateFilter: 1,
      isLoaded: false,
      showLoading: false,
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      tableData: [],
      columns: ['createdDate','nama', 'nomorMedicalRecord', 'nomorRegistrasi', 'namaDokter', 'tanggalKunjungan', 'jamKunjungan', 'reservasiVia','status','new','terkonfirmasi','pasienDatang','dibatalkan'],
      options:{
        filterByColumn: true,
        headings: {
            createdDate: "Tanggal Pendaftaran",
            nama: "Nama",
            nomorMedicalRecord: "Medical Record",
            nomorRegistrasi: "Nomor Registrasi HIS",
            namaDokter: "Dokter",
            tanggalKunjungan: "Jadwal Kunjungan",
            jamKunjungan: "Jam Kunjungan",
            reservasiVia: "Reservasi Via",
            status: "Status Terakhir",
            new: 'New',
            terkonfirmasi: 'Terkonfirmasi',
            pasienDatang: 'Pasien Datang',
            dibatalkan: 'Dibatalkan'
        },
        sortIcon: {
            base : 'fa',
            is: 'fa-sort',
            up: 'fa-sort-up',
            down: 'fa-sort-down'
        },
        dateColumns: ['createdDate', 'tanggalKunjungan'],
        dateFormat: 'DD-MMM-YYYY',
        listColumns: {
            reservasiVia: [
                {
                    id: 'MOBILE',
                    text: 'MOBILE'
                },
                {
                    id: 'WEB',
                    text: 'WEB'
                }
            ],
            status: [
                {
                    id: 'Baru',
                    text: 'Baru'
                },
                {
                    id: 'Terkonfirmasi',
                    text: 'Terkonfirmasi'
                },
                {
                    id: 'Dibatalkan',
                    text: 'Dibatalkan'
                },
                {
                    id: 'Pasien Datang',
                    text: 'Pasien Datang'
                }
            ],
        },
        templates: {
            new: timestamp,
            terkonfirmasi: timestamp,
            pasienDatang: timestamp,
            dibatalkan: timestamp
        },
        orderBy:{ column: 'tanggalKunjungan', ascending:false }
      },
      json_fields: {}
    };
  },
  methods: {
    checkNull: function(data){
        return (data) ? data : '';
    },
    exportExcelData: function(param){
        var space = (param) ? '\n' : ' ';
        const filteredData = [...this.$refs.table.allFilteredData];
        let dataExcel = filteredData.map(x => {
            var temp = {...x};
            temp.createdDate = x.createdDate ? moment(x.createdDate).format('DD-MMM-YYYY') : '' ;
            temp.tanggalKunjungan = moment(x.tanggalKunjungan).format('DD-MMM-YYYY');
            temp.new = this.checkNull(x.new.updateBy)+space+this.checkNull(x.new.updateDatetime);
            temp.terkonfirmasi = this.checkNull(x.terkonfirmasi.updateBy)+space+this.checkNull(x.terkonfirmasi.updateDatetime)+space+this.checkNull(x.terkonfirmasi.duration);
            temp.pasienDatang = this.checkNull(x.pasienDatang.updateBy)+space+this.checkNull(x.pasienDatang.updateDatetime)+space+this.checkNull(x.pasienDatang.duration);
            temp.dibatalkan= this.checkNull(x.dibatalkan.updateBy)+space+this.checkNull(x.dibatalkan.updateDatetime)+space+this.checkNull(x.dibatalkan.duration);
            return temp;
        })
        return dataExcel;
    },
    downloadPdf: function(){
        var data = this.exportExcelData(1);
        var column = []
        Object.keys(this.options.headings).forEach(key => {
          column.push({
              title: this.options.headings[key],
              dataKey: key
          })
        });
        var doc = new jsPDF('l', 'pt');
        var text = "Laporan Time Tracking Reservation"
        var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2);
        doc.text(text, xOffset, 30);
        doc.setFontSize(9);
        var texttitle = (this.dateFilter == 1) ? 'Periode Pendaftaran : ' : 'Periode Kunjungan : ';
        texttitle = texttitle +moment(this.dateStart).format('DD-MMM-YYYY') + " - " + moment(this.dateEnd).format('DD-MMM-YYYY')
        doc.text(texttitle, 40 , 50);
        doc.autoTable(column, data, {
            startY: 60,
            styles: {
                fontSize: 7,
            },
        });
        doc.save('Laporan Time Tracking Reservation.pdf');
    },
    trackingTime: function(data, targetStatus, defaultStatus = StatusReservasi.NEW.enum){
        var dataToReturn = {
            updateBy: null,
            updateDatetime: null,
            duration: null
        }
        const dataAudit = data.audit
        const jejakTarget = dataAudit.find(x => x.status == StatusReservasi[targetStatus].enum)
        let jejakNew = dataAudit.find(x => x.status == StatusReservasi[defaultStatus].enum)
        if(!jejakNew){
          jejakNew = {
            "lastModifiedDate": data.createdDate,
            "createdBy": data.createdBy,
          }
        }
        if( StatusReservasi[targetStatus].enum == StatusReservasi.NEW.enum){
            const isMobile = this.reservedVia(data.createdVia)
            dataToReturn.updateBy = (isMobile == 'MOBILE') ? 'Mobile' : 'WEB '+ jejakNew.createdBy ;
            dataToReturn.updateDatetime = moment(jejakNew.lastModifiedDate).format('DD-MMM-YYYY HH:mm:ss');
        } else {
            if(jejakTarget){
                var different = moment(jejakTarget.lastModifiedDate).diff(moment(jejakNew.lastModifiedDate));
                var duration = moment.duration(different);
                // var checkRegex =  jejakTarget.lastModifiedBy.match(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g);
                var checkRegex = this.checkPhoneRegex(jejakTarget.lastModifiedBy)
                dataToReturn.updateBy = (checkRegex != null) ? 'Check-in mobile' : jejakTarget.lastModifiedBy;
                dataToReturn.updateDatetime = moment(jejakTarget.lastModifiedDate).format('DD-MMM-YYYY HH:mm:ss');
                dataToReturn.duration = helpers.durationSpelling(duration);
            }
        }
        return dataToReturn;
    },
    checkPhoneRegex(item) {
      return item.match(/^62|^08/g)
    },
    jamKunjungan: function(data){
        const start = (data.jamMulai) ? data.jamMulai.substr(0, 5) : '';
        const selesai = (data.jamSelesai) ? data.jamSelesai.substr(0, 5) : '';
        return start+' - '+selesai;
    },
    // reservasiVia : function(data){
    //     var checkRegex = data.match(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g);
    //     return (checkRegex != null) ? 'MOBILE' : 'WEB'
    // },
    submitSearch: function () {
        this.showLoading = true;
        this.isLoaded = false;
        this.fetch();
    },
    reservedVia (item) {
      if(item == 'BACKOFFICE') return 'WEB'
      else return item
    },
    constructData: function(data){
        var dataResult = data.map(x => {
          try{
            var temp = {
                createdDate: x.createdDate ? moment(x.createdDate) : '',
                nama: x.nama,
                nomorMedicalRecord: x.nomorMedicalRecord,
                nomorRegistrasi: x.nomorRegistrasi,
                namaDokter: x.jadwal ? x.jadwal.dokter.nama : '-',
                tanggalKunjungan: moment(x.tanggalKunjungan),
                jamKunjungan: x.jadwal ? this.jamKunjungan(x.jadwal) : '',
                reservasiVia: this.reservedVia(x.createdVia),
                status: StatusReservasi[x.status].title,
                new: this.trackingTime(x, StatusReservasi.NEW.enum),
                terkonfirmasi: this.trackingTime(x, StatusReservasi.CONFIRM.enum),
                pasienDatang: this.trackingTime(x, StatusReservasi.ARRIVED.enum),
                dibatalkan: this.trackingTime(x, StatusReservasi.CANCEL.enum)
            }
            return temp;

          } catch {

          }
        });
        return dataResult;
    },
    async fetch() {
      var vx = this;
      var start = (this.dateStart == '') ? DateRange.MIN_DATE : this.dateStart;
      var end = (this.dateEnd == '') ? DateRange.MAX_DATE : this.dateEnd;
      if (this.dateFilter == 1) {
        var dateStart = moment(start).format('YYYY-MM-DD');
        var dateEnd = moment(end).format('YYYY-MM-DD');
        var params = {
          "size" : 50,
          "createdDate.greaterOrEqualThan": dateStart,
          "createdDate.lessOrEqualThan": dateEnd
        };
      } else if (this.dateFilter == 2) {
        var params = {
          "size" : 50,
          "tanggalKunjungan.greaterOrEqualThan": start,
          "tanggalKunjungan.lessOrEqualThan": end
        };
      }
    
      new Promise((resolve, reject) => {
        ReservationRepository.reportReservasiAudit(params, resolve, reject);
      })
        .then(response => {
            this.tableData = this.constructData(response.data);
            this.showLoading = false;
            this.isLoaded = true;
        }).catch(error => {
          var msg = (error.message) ? error.message : error;
          this.showToastError('Reservation : ' + msg);
        })

    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    showToastError: function (message) {
      toastr.error(message);
    },
  },
  created() {
      this.json_fields = helpers.objectFlip(this.options.headings);
  }
};
</script>
